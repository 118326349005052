.table_main table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.table_main td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.table_main th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    background-color: lightgrey;
}
